import React, { useState, useEffect } from 'react';
import './App.css';

import Login from './components/Login';
import Logger from './components/Logger';
import Navigation from './components/Navigation';
import Admin from './components/Admin';
import MileageTracker from './components/MileageTracker';
import api from './api';
import UserContext from './UserContext';
import { ModalProvider } from './components/ModalContext';

function App() {
    const [isAuth, setIsAuth] = useState(false);
    const [user, setUser] = useState(null);
    const [page, setPage] = useState('logger');

    useEffect(() => {
        fetch("/auth/test")
        .then(response => response.json())
        .then(obj => {
            if(obj.isAuthenticated) {
                if(obj.user.roles.includes('admin') && !obj.user.roles.includes('user')) {
                    setPage('admin');
                }

                api.getWorkOrderEntries()
                .then(workOrders => {
                    localStorage.setItem('workOrders', JSON.stringify(workOrders));
                })
                .catch(err => {
                    console.error(err);
                    alert("Failed to read WorkOrders; will not be able to properly create/edit entries.");
                })
                .finally(() => {
                    // the following is only set after acquiring the work orders in order to ensure they are available across the site
                    setIsAuth(true);
                    setUser(obj.user);
                })
            }
        });
    }, []);

    return(
        <UserContext.Provider value={{loggedInUser: user}}>
            <ModalProvider>
                <div id="App">
                    {!isAuth ? 
                        <Login/>
                    :
                        <>
                        <Navigation activePage={page} setPage={setPage}/>

                        {page === "logger" ?
                            <Logger/>
                        :null}

                        {page === "admin" ?
                            <Admin/>
                        :null}

                        {page === "mileage" ?
                            <MileageTracker/>
                        :null}
                        </>
                    }
                </div>
            </ModalProvider>
        </UserContext.Provider>
    )
}

export default App;
