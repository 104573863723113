import React, {useContext} from 'react';

import UserContext from '../UserContext';

function Navigation(props) {
    const userContext = useContext(UserContext);
    const adminIsActive = props.activePage === "admin";
    const loggerIsActive = props.activePage === "logger";
    const mileageIsActive = props.activePage === "mileage";
    const goToAdmin = (event) => {
        event.preventDefault();
        props.setPage('admin');
    }
    const goToLogger = (event) => {
        event.preventDefault();
        props.setPage('logger');
    }
    const goToMileage = (event) => {
        event.preventDefault();
        props.setPage('mileage');
    }
    return (
        <>
        {userContext.loggedInUser.roles.length > 1 ? 
            <div className="navigation">
                <div className="navbar navbar-expand bg-dark">
                    <div className="container">
                        <div>
                            <ul className="nav nav-tabs">
                                {userContext.loggedInUser.roles.includes('admin') ?
                                <li className="nav-item">
                                    <a className={adminIsActive ? "nav-link active text-white bg-secondary" : "nav-link text-white"} href="#" onClick={goToAdmin}>
                                        <i className="fas fa-user"/>
                                        &nbsp; Admin
                                    </a>
                                </li>
                                :null}
                                <li className="nav-item">
                                    <a className={loggerIsActive ? "nav-link active text-white bg-secondary" : "nav-link text-white"} href="#" onClick={goToLogger}>
                                        <i className="fas fa-chart-bar"/>
                                        &nbsp; Timecard
                                    </a>
                                </li>
                                {userContext.loggedInUser.roles.includes('technician') || userContext.loggedInUser.roles.includes('mileage') ?
                                <li className="nav-item">
                                    <a className={mileageIsActive ? "nav-link active text-white bg-secondary" : "nav-link text-white"} href="#" onClick={goToMileage}>
                                        <i className="fas fa-car-side"/>
                                        &nbsp; Mileage
                                    </a>
                                </li>
                                :null}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        : null}
        </>
    )
}

export default Navigation;