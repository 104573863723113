import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';

import helpers from '../helpers';
import UserContext from '../UserContext';
import MonthSelector from './MonthSelector';
import LoadingSpinner from './LoadingSpinner';
import api from '../api';

function handleError(e) {
    console.error(e);
    if(e.status === 422) {
        for(const key in e.json) {
            alert(`ERROR: ${e.json[key].message}`);
        }
    }
    else {
        alert('An unhandled error occurred and the process could not be completed.');
    }
}

const MileageEntryRow = ({entry, index, updateEntryAtIndex, removeEntryAtIndex}) => {
    const [workingEntry, setWorkingEntry] = useState(entry);
    const [isEditing, setIsEditing] = useState(entry._id ? false : true);
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const handleChange = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        const newWorkingEntry = JSON.parse(JSON.stringify(workingEntry));
        newWorkingEntry[`${name}`] = value;
        setWorkingEntry(newWorkingEntry);
    }

    const handleDelete = (e) => {
        e.preventDefault();
        setIsDeleting(true);
        if(workingEntry._id) {
            api.deleteMileageEntry(workingEntry._id, undefined)
            .then(_ => {
                removeEntryAtIndex(index);
            })
            .catch(e => {
                setIsDeleting(false);
                handleError(e);
            })
        }
        else {
            removeEntryAtIndex(index);
        }
    }

    const handleSave = (e) => {
        e.preventDefault();
        setIsSaving(true);
        if(!entry._id) {
            api.createMileageEntry(workingEntry, undefined)
            .then(savedEntry => {
                setWorkingEntry(savedEntry);
                updateEntryAtIndex(index, savedEntry);
                setIsEditing(false);
            })
            .catch(e => {
                handleError(e);
            })
            .finally(() => {
                setIsSaving(false);
            })
        }
        else {
            api.updateMileageEntry(entry._id, workingEntry, undefined)
            .then(savedEntry => {
                setWorkingEntry(savedEntry);
                updateEntryAtIndex(index, savedEntry);
                setIsEditing(false);
            })
            .catch(e => {
                handleError(e);
            })
            .finally(() => {
                setIsSaving(false);
            })
        }
    }

    return (
        <tr>
            <td>
                {isEditing ? 
                <input className="form-control" type="date" name="day" value={workingEntry.day} onChange={handleChange} disabled={isSaving || isDeleting}/>
                :
                <>{helpers.dateFromISOFragment(entry.day).toLocaleDateString()}</>
                }
            </td>
            <td>
                {isEditing ?
                <textarea style={{resize: 'vertical'}} className="form-control" name="description" value={workingEntry.description} onChange={handleChange} disabled={isSaving || isDeleting}/>
                :
                <>{entry.description.split('\n').map((p, i) => <><span key={i}>{p}</span><br/></>)}</>
                }
            </td>
            <td>
                {isEditing ? 
                <input className="form-control" type="number" min={0} name="mileage" value={workingEntry.mileage} onChange={handleChange} disabled={isSaving || isDeleting}/>
                :
                <>{entry.mileage.toFixed(2)}</>
                }
            </td>
            <td>
                <>
                {isEditing ?
                <> 
                <button className="btn btn-sm btn-success me-1" onClick={handleSave} disabled={isSaving || isDeleting}>
                    <i className={isSaving ? "fas fa-spinner" : "fas fa-floppy-disk"}/>
                </button>
                {workingEntry._id ?
                <button className="btn btn-sm btn-secondary me-1" onClick={(e) => {e.preventDefault(); setWorkingEntry(entry); setIsEditing(false)}} disabled={isSaving || isDeleting}>
                    <i className="fas fa-arrow-left"/>
                </button>
                :null}
                </>
                :
                <button className="btn btn-sm btn-secondary me-1" onClick={(e) => {e.preventDefault(); setIsEditing(true)}} disabled={isSaving || isDeleting}>
                    <i className="fas fa-pencil"/>
                </button>
                }
                <button className="btn btn-sm btn-danger" onClick={handleDelete} disabled={isSaving || isDeleting}>
                    <i className={isDeleting ? "fas fa-spinner" : "fas fa-times"}/>
                </button>
                </>
            </td>
        </tr>
    )
}

const MileageTracker = ({}) => {
    const userContext = useContext(UserContext);
    const [monthBaseDayStr, setBaseDayStr] = useState(helpers.dateToISOFragment(new Date()));
    const [workingEntries, setWorkingEntries] = useState(null);

    useEffect(() => {
        if(workingEntries === null) {
            const tokens = monthBaseDayStr.split('-');
            if(tokens.length < 3) {
                console.error('monthBaseDayStr improperly formatted');
            }
            else {
                const monthStr = tokens[1];
                const yearStr = tokens[0];
                api.getMileageEntriesForUserInMonth(userContext.loggedInUser.email, monthStr, yearStr, undefined)
                .then(newEntries => setWorkingEntries(newEntries))
                .catch(e => {
                    if(e.name !== "AbortError") {
                        console.error(e);
                        alert("Failed to retrieve entries. Please refresh and try again. Alert the website administrator if the problem persists.");
                    }
                    else {
                        console.log('getMileageEntriesForUserInMonth aborted');
                    }
                })
            }
        }
    }, [workingEntries]);

    useEffect(() => {
        setWorkingEntries(null);
    }, [monthBaseDayStr]);

    const addNewEntry = (e) => {
        e.preventDefault();
        setWorkingEntries([...workingEntries, {
            user: {
                first: userContext.loggedInUser.first,
                last: userContext.loggedInUser.last,
                email: userContext.loggedInUser.email
            },
            day: helpers.dateToISOFragment(new Date()),
            mileage: 0,
            description: '',
        }])
    }

    const updateEntryAtIndex = (index, entry) => {
        let newEntries = [];
        for(let i = 0; i < workingEntries.length; i++) {
            if(i !== index) {
                newEntries.push(workingEntries[i])
            }
            else {
                newEntries.push(entry);
            }
        }
        setWorkingEntries(newEntries);
    }

    const removeEntryAtIndex = (index) => {
        setWorkingEntries(workingEntries.filter((_, i) => i !== index));
    }

    return (
        <div className="container">
            <center>
                <h2>
                    <MonthSelector setBaseDayStr={setBaseDayStr}/>
                </h2>
            </center>
            {workingEntries === null ? 
                <LoadingSpinner size={75}/>
            : 
            <>
            <div className="row mb-2">
                <div className="col">
                    <button className="btn btn-success float-end" onClick={addNewEntry}>
                        <i className="fas fa-plus"/>&nbsp;Add Row
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {workingEntries.length > 0 ? 
                    <table className="table table-striped table-bordered table-layout-fixed w-100">
                        <colgroup>
                            <col span="1" style={{width: '15%'}}/>
                            <col span="1" style={{width: '55%'}}/>
                            <col span="1" style={{width: '10%'}}/>
                            <col span="1" style={{width: '10%'}}/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Sites Visited</th>
                                <th>Mileage</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {workingEntries.map((entry, i) => <MileageEntryRow key={i} entry={entry} index={i} updateEntryAtIndex={updateEntryAtIndex} removeEntryAtIndex={removeEntryAtIndex}/>)}
                        </tbody>
                    </table>
                    :
                    <p className="text-muted"><i>No entries for this month yet.</i></p>
                    }
                </div>
            </div>
            </>
            }
        </div>
    )
}

export default MileageTracker;