import React, {useState, useEffect, useContext} from 'react';

import helpers from '../helpers';
import api from '../api';
import LoadingSpinner from './LoadingSpinner';
import EntriesTable from './EntriesTable';
import BlobDownloadList from './BlobDownloadList';

const HMISReportsWizard = props => {
    const today = new Date();
    const [enableQb, setEnableQb] = useState(false);
    const [step, setStep] = useState(1);
    const [startDate, setStartDate] = useState(helpers.dateToISOFragment(today));
    const [endDate, setEndDate] = useState(helpers.dateToISOFragment(today));
    const [workOrders, setWorkOrders] = useState(null);
    const [selectedWorkOrderNumbers, setSelectedWorkOrderNumbers] = useState([]);
    const [entriesByUser, setEntriesByUser] = useState(null);
    const [entriesByWorkOrder, setEntriesByWorkOrder] = useState(null);
    const [reportBlobsByWorkOrder, setReportBlobsByWorkOrder] = useState(null);

    useEffect(() => {
        fetch('/qb/token')
        .then(response => {
            if(response.status === 200) {
                setEnableQb(true);
                return null;
            }
            else if(response.status === 302) {
                return response.text()
            }
            else {
                throw {
                    status: response.status,
                    text: response.text()
                }
            }
        })
        .then(data => {
            if(data && data.startsWith('https')) {
                console.log(`authURI: ${data}`);
            }
        })
        .catch(e => {
            if(e.status === 401) {
                alert("Your session has expired. The page will automatically refresh and you can sign back in.");
                window.open('/', '_self');
            }
            else {
                console.error(e);
                alert("Error authenticating QuickBooks.");
            }
        })
    }, []);

    useEffect(() => {
        if(step < 1) {
            setStep(1);
        }
        if(step > 4) {
            setStep(4);
        }
        if(step < 3) {
            setEntriesByUser(null);
            setEntriesByWorkOrder(null);
        }
        
        if(step === 1) {
            setWorkOrders(null);
            setSelectedWorkOrderNumbers([]);
        }
        else if(step === 2 && workOrders === null) {
            api.getWorkOrderEntriesInRange(startDate, endDate)
            .then(workOrders => {
                setWorkOrders(workOrders);
            })
            .catch(err => {
                console.error(err);
                alert("ERROR: Failed to read Work Orders");
            })
        }
        else if(step === 3) {
            getEntries();
        }
        else if(step === 4) {
            runReports();
        }
    }, [step]);

    const handleChange = (event) => {
        event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;
        if(name === "startDate") {
            setStartDate(value);
        }
        else if(name === "endDate") {
            setEndDate(value);
        }
    }

    const handleSelectChange = (event) => {
        const workOrderNumber = event.target.name;
        if(event.target.checked) {
            if(!selectedWorkOrderNumbers.includes(workOrderNumber)) {
                setSelectedWorkOrderNumbers([...selectedWorkOrderNumbers, workOrderNumber]);
            }
        }
        else {
            let newSelected = [];
            for(const selectedNumber of selectedWorkOrderNumbers) {
                if(selectedNumber !== workOrderNumber) {
                    newSelected.push(selectedNumber);
                }
            }
            setSelectedWorkOrderNumbers(newSelected);
        }
    }

    const getEntries = async() => {
        const selectedWorkOrders = workOrders.filter((workOrder) => selectedWorkOrderNumbers.includes(workOrder.workOrderNumber));
        let entriesByWorkOrder = {};
        for(const workOrder of selectedWorkOrders) {
            try {
                entriesByWorkOrder[workOrder.workOrderNumber] = (await api.getEntriesByWorkOrderNumber(workOrder.workOrderNumber)).filter((entry) => entry.day >= startDate && entry.day <= endDate);
            }
            catch(err) {
                console.error(err);
                alert(`ERROR: Failed to read Entries for Work Order ${workOrder.workOrderNumber}`);
            }
        }
        setEntriesByWorkOrder(entriesByWorkOrder);
        let entriesByUser = {};
        for(const workOrderNumber in entriesByWorkOrder) {
            for(const entry of entriesByWorkOrder[workOrderNumber]) {
                if(entriesByUser[entry.user.email] === undefined) {
                    entriesByUser[entry.user.email] = [entry]
                }
                else {
                    entriesByUser[entry.user.email].push(entry);
                }
            }
        }
        let entriesByUserAsArray = [];
        for(const userEmail in entriesByUser) {
            const fullUserObject = entriesByUser[userEmail][0].user;
            entriesByUserAsArray.push([fullUserObject, entriesByUser[userEmail].sort((a, b) => a.day < b.day ? -1 : 1)])
        }
        console.log(entriesByUserAsArray);
        setEntriesByUser(entriesByUserAsArray);
    }

    const sortInvoice = (invoice) => {
        const chromeSort = (a,b) => {
            const dateA = new Date(`${a.SalesItemLineDetail.ServiceDate}T13:00:00`);
            const dateB = new Date(`${b.SalesItemLineDetail.ServiceDate}T13:00:00`);
            return dateA.getTime() < dateB.getTime() ? -1 : 1
        }

        if(navigator.userAgent.includes("Chrome"))
            return invoice.Line.filter(line => line.DetailType === "SalesItemLineDetail").sort(chromeSort);
        else
            return invoice.Line.filter(line => line.DetailType === "SalesItemLineDetail").sort((a, b) => a.SalesItemLineDetail.ServiceDate > b.SalesItemLineDetail.ServiceDate ? 1 : -1);
    }

    const frontPaddedZeroes = (number) => {
        const PADDING = 4;
        const nZeroes = (PADDING - (number.toString().length)) >= 0 ? PADDING - (number.toString().length) : 0;
        let str = "";
        for(let i = 0; i < nZeroes; i++) {
            str += "0";
        }
        str += number.toString();
        return str;
    }

    const invoiceToCostCollectionsDataSet = (invoice, workOrderNumber) => {
        // Returns a dataSet representing the Cost Collections of an invoice
        const excelHeaders = [
            {
                string: "Invoice No",
                key: "invoice_number"
            },
            {
                string: "Invoice Line",
                key: "line"
            },
            {
                string: "Invoice Date",
                key: "date"
            },
            {
                string: "Contractor Invoice ID",
                key: "contractor_invoice_id"
            },
            {
                string: "Contract Number",
                key: "contract_number"
            },
            {
                string: "Contract Release",
                key: "contract_release"
            },
            {
                string: "Charge Code (CACN)",
                key: "cacn"
            },
            {
                string: "Cost Invoiced",
                key: "cost"
            }
        ];
        const date = new Date(invoice.TxnDate);
        let data = [];
        const chronologicalLineItems = sortInvoice(invoice);
        for(let i = 0; i < chronologicalLineItems.length; i++) {
            const line = chronologicalLineItems[i];
            const cacn = line.Description.length >= 6 && line.Description.substring(0,6).match(/\d\d\d\d\d\d/g) ? line.Description.substring(0,6).match(/\d\d\d\d\d\d/g)[0] : null;
            const amount = line.Amount;
            if(cacn) {
                let workOrderNumberTokens = workOrderNumber.split('-');
                data.push({
                    invoice_number: {string: invoice.DocNumber},
                    line: {string: frontPaddedZeroes(i+1)},
                    date: {string: helpers.dateToISOFragment(date).replace(/-/g, '')},
                    contractor_invoice_id: {string: "208352"}, // CBIT's unique ID
                    contract_number: {string: "000" + workOrderNumberTokens[0]},
                    contract_release: {string: "0" + workOrderNumberTokens[workOrderNumberTokens.length - 1]},
                    cacn: {string: cacn},
                    cost: {
                        number: amount,
                        style: {
                            numberFormat: "0.00"
                        }
                    }
                });
            }
            else {
                console.warn(`(Cost Collections) No CACN on line ${i} for Invoice (ID: ${invoice.Id}, DocNumber ${invoice.DocNumber}), report will be incomplete`);
                alert(`(Cost Collections) No CACN on line ${i} for Invoice (ID: ${invoice.Id}, DocNumber ${invoice.DocNumber}), report will be incomplete`);
            }
        }
        return {
            headers: excelHeaders,
            data: data
        };
    }

    // Consolidate by positionTitle and CACN
    const consolidatedEntries = (entries) => {
        let pairs = []; // {title, cacn, entries}
        for(const entry of entries) {
            let foundPair = false;
            for(let i = 0; i < pairs.length; i++) {
                if(pairs[i].title === entry.workOrder.positionTitle && pairs[i].cacn === entry.cacn) {
                    pairs[i].entries.push(entry);
                    pairs[i].hours += entry.adjustedHours;
                    foundPair = true;
                }
            }
            if(!foundPair) {
                pairs.push({
                    title: entry.workOrder.positionTitle,
                    cacn: entry.cacn,
                    entries: [entry],
                    hours: entry.adjustedHours
                })
            }
        }
        return pairs;
    }

    const generateInvoice = async(workOrder, entries) => {
        if(!enableQb) {
            alert("ERROR: Cannot generate QB Invoice because QB integration could not be authenticated.");
            return null;
        }
        try {
            const term = await api.getQbTermByName('Net 30');
            const userItemPairs = await api.getUserItemPairsInRange(workOrder.startDate, workOrder.endDate);
            const qbCustomer = await api.getQbCustomerByContractNumber(workOrder.mainContractNumber.split('-')[0]);
            let lineItems = [];
            const entriesSortedByDay = entries.sort((a, b) => a.day < b.day ? -1 : 1);
            const entriesBySet = consolidatedEntries(entriesSortedByDay.filter(entry => entry.type === "HMIS"));
            for(const set of entriesBySet) {
                const pair = (userItemPairs.find((pair) => pair.user === set.entries[0].user.email));
                const itemId = pair.id;
                const price = pair.price;
                lineItems.push({
                    description: `${set.cacn} - ${set.entries[0].description}`,
                    quantity: set.hours,
                    price: price,
                    id: itemId,
                    class: {
                        value: "200000000001712859",
                        name: "Labor:Federal"
                    },
                });
            }

            if(lineItems.length > 0) {
                const invoice = await api.createQbInvoice(qbCustomer.Id, lineItems, term);
                return invoice
            }
        }
        catch(e) {
            if(e.name !== "AbortError") {
                console.error(e);
                alert(e.toString());
            }
        }
        return null;
    }

    const rateForWorkOrderByUser = (userEmail, workOrder) => {
        for(const position of workOrder.positions) {
            for(const user of position.users) {
                if(user.email === userEmail) {
                    return position.rate;
                }
            }
        }
        throw new Error(`Could not match ${userEmail} to Work Order ${workOrder.workOrderNumber}`);
    }

    const rateForWorkOrderByTitle = (title, workOrder) => {
        for(const position of workOrder.positions) {
            if(position.positionTitle === title) {
                return position.rate;
            }
        }
        throw new Error(`Could not match title ${title} to Work Order ${workOrder.workOrderNumber}`);
    }

    const generateAmountsPerCACNDataSet = (workOrder, entries) => {
        const headers = [
            {
                string: "CACN",
                key: "cacn"
            },
            {
                string: "Amount",
                key: "amount"
            }
        ]
        let entriesByCACN = {}
        for(const entry of entries) {
            if(!entriesByCACN[entry.cacn]) {
                entriesByCACN[entry.cacn] = [entry]
            }
            else {
                entriesByCACN[entry.cacn].push(entry);
            }
        }
        let data = [];
        for(const cacn in entriesByCACN) {
            // Increase accuracy by reducing number of multiplication operations by getting the total number of hours per multiplier before multiplying
            // If all entrys for a given CACN use the same rate, this will perform exactly one multiplication (the best case)
            let hoursAndMultipliers = [];
            for(const entry of entriesByCACN[cacn]) {
                hoursAndMultipliers.push({hours: entry.adjustedHours, multiplier: rateForWorkOrderByUser(entry.user.email, workOrder)});
            }
            let hoursPerMultiplier = {};
            for(const pair of hoursAndMultipliers) {
                if(hoursPerMultiplier[pair.multiplier] === undefined) {
                    hoursPerMultiplier[pair.multiplier] = pair.hours;
                }
                else {
                    hoursPerMultiplier[pair.multiplier] += pair.hours;
                }
            }
            let amountSum = 0;
            for(const multiplier in hoursPerMultiplier) {
                amountSum += hoursPerMultiplier[multiplier] * multiplier;
            }
            data.push({
                cacn: {string: cacn},
                amount: {number: amountSum, style: {numberFormat: "$#,##0.00; ($#,##0.00); -"}}
            })
        }
        return {
            headers: headers,
            data: data
        }
    }

    const generateAmountsPerTitleDataSet = (workOrder, entries) => {
        const headers = [
            {
                string: "Position Title",
                key: "title"
            },
            {
                string: "Amount",
                key: "amount"
            }
        ]
        let entriesByTitle = {}
        for(const position of workOrder.positions) {
            entriesByTitle[position.positionTitle] = [];
        }
        for(const entry of entries) {
            entriesByTitle[entry.workOrder.positionTitle].push(entry);
        }
        let data = [];
        for(const title in entriesByTitle) {
            // A title has a given rate, we can still multiply once at the end
            let hoursSum = 0;
            for(const entry of entriesByTitle[title]) {
                hoursSum += entry.adjustedHours;
            }
            data.push({
                title: {string: title},
                amount: {number: hoursSum * rateForWorkOrderByTitle(title, workOrder), style: {numberFormat: "$#,##0.00; ($#,##0.00); -"}}
            })
        }
        return {
            headers: headers,
            data: data
        }
    }

    const runReports = async() => {
        const selectedWorkOrders = workOrders.filter((workOrder) => selectedWorkOrderNumbers.includes(workOrder.workOrderNumber));
        let reportBlobs = [];
        try {
            let pairs = null;
            try {
                pairs = await api.getUserItemPairsInRange(startDate, endDate);
            }
            catch(e) {
                if(e.status === 400) {
                    alert(`ERROR: The process has been halted because QB Items could not be found for the following users: ${e.json.map(obj => obj.user).join('; ')} -- this usually happens when the QB item is not verified. Please find the applicable WOs in the date range for these users and ensure their QB items have been validated.`);
                    return;
                }
                else {
                    throw e;
                }
            }
            for(const workOrder of selectedWorkOrders) {
                const entries = entriesByWorkOrder[workOrder.workOrderNumber];

                // Labor Summary (Hours)
                const laborSummaryHoursDataSet = helpers.generateLaborSummaryDataSet(entries, helpers.totalHours);
                const laborSummaryHoursBlob = await api.createXLSX(laborSummaryHoursDataSet);

                // Labor Summary (Amounts)
                const laborSummaryAmountsDataSet = helpers.generateLaborSummaryDataSet(entries, helpers.totalAmount, pairs);
                const laborSummaryAmountsBlob = await api.createXLSX(laborSummaryAmountsDataSet);

                // QB Invoice
                const invoice = await generateInvoice(workOrder, entries);

                // Cost Collections
                const costCollectionsDataSet = invoiceToCostCollectionsDataSet(invoice, workOrder.workOrderNumber);
                const costCollectionsBlob = await api.createXLSX(costCollectionsDataSet);

                // Amounts by CACN
                const amountsByCACNDataSet = generateAmountsPerCACNDataSet(workOrder, entries);
                const amountsByCACNBlob = await api.createXLSX(amountsByCACNDataSet);

                // Amounts by Title
                const amountsByTitleDataSet = generateAmountsPerTitleDataSet(workOrder, entries);
                const amountsByTitleBlob = await api.createXLSX(amountsByTitleDataSet);

                // HMIS Report
                let hmisReportDataSet, hmisReportBlob;
                try {
                    hmisReportDataSet = await api.getHmisMonthlyDataSet(startDate, endDate, workOrder.workOrderNumber);
                    hmisReportBlob = await api.createXLSX(hmisReportDataSet);
                }
                catch(err) {
                    console.error(err);
                    alert(`ERROR: Failed to create HMIS Entry Details report for Work Order ${workOrder.workOrderNumber}`);
                }

                // Lock entries
                try {
                    await api.lockEntries(entries.map((entry) => entry._id));
                }
                catch(err) {
                    console.error(err);
                    alert(`ERROR: Failed to lock processed entries for Work Order ${workOrder.workOrderNumber}; users will still be able to edit them`);
                }

                // Create Bundle
                try {
                    await api.createHMISReportBundle({
                        startDate: startDate,
                        endDate: endDate,
                        workOrder: {
                            number: workOrder.workOrderNumber,
                            positionTitle: workOrder.positions.map(pos => pos.positionTitle).join('; ')
                        },
                        reports: {
                            laborSummaryHours: {
                                filename: `Labor Summary Report ${workOrder.workOrderNumber} (Hours).xlsx`,
                                dataSets: [laborSummaryHoursDataSet]
                            },
                            laborSummaryAmounts: {
                                filename: `Labor Summary Report ${workOrder.workOrderNumber} (Amounts).xlsx`,
                                dataSets: [laborSummaryAmountsDataSet]
                            },
                            costCollections: {
                                filename: `Cost Collections ${workOrder.workOrderNumber} rel 0.xlsx`,
                                dataSets: [costCollectionsDataSet]
                            },
                            amountsPerCACN: {
                                filename: `Amounts per CACN ${workOrder.workOrderNumber}.xlsx`,
                                dataSets: [amountsByCACNDataSet]
                            },
                            amountsPerTitle: {
                                filename: `Amounts per Title ${workOrder.workOrderNumber}.xlsx`,
                                dataSets: [amountsByTitleDataSet]
                            },
                            entryDetailsReport: {
                                filename: `HMIS Entry Details ${workOrder.workOrderNumber}.xlsx`,
                                dataSets: [hmisReportDataSet]
                            } 
                        },
                        qbInvoice: {
                            id: invoice.Id.toString(),
                            docNumber: invoice.DocNumber.toString()
                        }
                    })
                }
                catch(err) {
                    console.error(err);
                    alert(`ERROR: Failed to save report bundle for Work Order ${workOrder.workOrderNumber}; reports will not be accessible later.`);
                }

                reportBlobs.push([workOrder, [
                    {
                        invoice: invoice
                    },
                    {
                        filename: `Labor Summary Report ${workOrder.workOrderNumber} (Hours).xlsx`,
                        blob: laborSummaryHoursBlob
                    },
                    {
                        filename: `Labor Summary Report ${workOrder.workOrderNumber} (Amounts).xlsx`,
                        blob: laborSummaryAmountsBlob
                    },
                    {
                        filename: `Cost Collections ${workOrder.workOrderNumber} rel 0.xlsx`,
                        blob: costCollectionsBlob
                    },
                    {
                        filename: `Amounts per CACN ${workOrder.workOrderNumber}.xlsx`,
                        blob: amountsByCACNBlob
                    },
                    {
                        filename: `Amounts per Title ${workOrder.workOrderNumber}.xlsx`,
                        blob: amountsByTitleBlob
                    },
                    {
                        filename: `HMIS Entry Details ${workOrder.workOrderNumber}.xlsx`,
                        blob: hmisReportBlob
                    }
                ]]);
            }
        }
        catch(err) {
            console.error(err);
            alert(`An unhandled error has occurred and the job cannot continue: ${err.toString()}`);
            return;
        }
        setReportBlobsByWorkOrder(reportBlobs);
    }

    const nextStep = (event) => {
        event.preventDefault();
        setStep(step+1);
    }

    const prevStep = (event) => {
        event.preventDefault();
        setStep(step-1);
    }

    const dateFormatStr = (dateStr) => {
        const date = helpers.dateFromISOFragment(dateStr);
        return date.toLocaleDateString();
    }

    return(
        <div>
            <h3>HMIS Reports</h3>
            <div>
                <h4>Step 1: Choose Date Range</h4>
                <div className="row">
                    <div className="col d-flex flex-column align-items-center">
                        <label className="form-label"><b><u>Start Date:</u></b></label>
                        <input className="form-control w-fit" type="date" value={startDate} name="startDate" onChange={handleChange} disabled={step !== 1}/>
                    </div>
                    <div className="col d-flex flex-column align-items-center">
                        <label className="form-label"><b><u>End Date:</u></b></label>
                        <input className="form-control w-fit" type="date" value={endDate} name="endDate" onChange={handleChange} disabled={step !== 1}/>
                    </div>
                </div>
                {step === 1 ? 
                    <button className="btn btn-primary" onClick={nextStep}>
                        <i className="fas fa-arrow-right"/>&nbsp;Continue
                    </button>
                :null}
            </div>
            {step > 1 ? 
            <div>
                <h4>Step 2: Select Work Orders</h4>
                {workOrders ? 
                <>
                {workOrders.map((workOrder, i) => <div className="row text-start" key={i}>
                    <div className="col">
                        <div className="form-check">
                            <input id={`workOrderSelect${i}`} name={workOrder.workOrderNumber} type="checkbox" className="form-check-input" checked={selectedWorkOrderNumbers.includes(workOrder.workOrderNumber)} onChange={handleSelectChange} disabled={step !== 2}/>
                            <label className="form-check-label" htmlFor={`workOrderSelect${i}`}>
                                {workOrder.workOrderNumber}: {workOrder.positions.map(pos => pos.positionTitle).join('; ')} (Active Between {dateFormatStr(workOrder.startDate)} - {dateFormatStr(workOrder.endDate)})
                            </label>
                        </div>
                    </div>
                </div>)}
                </>
                : 
                <LoadingSpinner size={75}/>}
                {step === 2 ? 
                <>
                <button className="btn btn-secondary" onClick={prevStep}>
                    <i className="fas fa-arrow-left"/>&nbsp;Go Back
                </button>
                <button className="btn btn-primary" onClick={nextStep} disabled={selectedWorkOrderNumbers.length === 0}>
                    <i className="fas fa-arrow-right"/>&nbsp;Continue
                </button>
                </>
                :null}
            </div>
            :null}
            {step > 2 ?
            <div>
                <h4>Step 3: Preview Entries</h4>
                <p>The following entries will be processed by the reports. <b>Processing these entries will "lock" them, rendering users unable to make future changes.</b></p>
                {entriesByUser ? 
                    <>
                    {entriesByUser.map(([user, entries], i) => <div key={i}>
                        <h5>Entries for {user.first} {user.last} ({user.email})</h5>
                        <EntriesTable forUser={user} entries={entries} showDay readonly showTotal/>
                    </div>)}
                    {step === 3 ? 
                    <>
                    <button className="btn btn-secondary" onClick={prevStep}>
                        <i className="fas fa-arrow-left"/>&nbsp;Go Back
                    </button>
                    <button className="btn btn-primary" onClick={nextStep} disabled={selectedWorkOrderNumbers.length === 0}>
                        <i className="fas fa-arrow-right"/>&nbsp;Run Reports
                    </button>
                    </>
                    :null}
                    </>
                : <LoadingSpinner size={75}/>}
            </div>
            :null}
            {step === 4 ?
                <>
                {reportBlobsByWorkOrder !== null ? 
                    <>
                    {reportBlobsByWorkOrder.map(([workOrder, files], i) => <div key={i} className="mt-3">
                        <h5>Results for {workOrder.workOrderNumber}: {workOrder.positions.map(pos => pos.positionTitle).join('; ')}</h5>
                        {files.find(file => file.invoice !== undefined) ? <span>
                            <b>QB Invoice:</b>&nbsp;
                            <a href={`https://qbo.intuit.com/app/invoice?txnId=${files.find(file => file.invoice !== undefined).invoice.Id}`} target="_blank" rel="noopener noreferrer">Invoice {files.find(file => file.invoice !== undefined).invoice.DocNumber}</a>
                        </span>
                        :null}
                        <BlobDownloadList files={files.filter(file => file.invoice === undefined)} zipName={`HMIS Reports ${workOrder.workOrderNumber}.zip`} id={i}/>
                    </div>)}
                    </>
                :
                <LoadingSpinner size={75}/>
                }
                </>
            :null}
        </div>
    )
}

export default HMISReportsWizard;