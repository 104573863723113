import helpers from "./helpers";

/**
 * Basic, generic response handling that resolves with the response data or otherwise rejects with an error.
 * @param {Response} response 
 * @param {function} resolve 
 * @param {function} reject 
 */
function handleResponse(response, resolve, reject, parseAs = 'json') {
    if(response.status === 200 || response.status === 201) {
        response[parseAs]()
            .then(data => resolve(data))
            .catch(err => {
                reject(err);
            })
    }
    else if(response.status === 401) {
        alert("Your session has expired. The page will automatically refresh and you can sign back in.");
        window.open('/', '_self');
    }
    else {
        response.text()
            .then(text => {
                try {
                    reject({status: response.status, json: JSON.parse(text)});
                }
                catch(err) {
                    // Not valid JSON
                    reject({status: response.status, text: text});
                }
            })
            .catch(err => {
                reject(err);
            })
    }
}

/**
 * Get the array of all Users from the API.
 * @param {AbortSignal} signal The signal for aborting the API call. 
 * @returns {Promise} A promise that resolves with the array of Users.
 */
function getUsers(signal) {
    return new Promise((resolve, reject) => {
        let opts = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        if(signal) {
            opts.signal = signal;
        }
        fetch('/api/user', opts)
            .then(response => {
                handleResponse(response, resolve, reject);
            })
            .catch(e => {
                reject(e);
            })
    })
}

/**
 * Get an array of all `Entry`s for a given user.
 * @param {string} userEmail The user's registered email address.
 * @param {string} startDate YYYY-MM-DD format date string.
 * @param {string} endDate YYYY-MM-DD format date string.
 * @param {object} opts Options to pass to the `fetch` call (`headers`, `signal`, etc.) 
 * @returns {Promise} A promise that resolves with an array of `Entry`
 */
function getEntriesForUser(userEmail, startDate, endDate, opts) {
    return new Promise((resolve, reject) => {
        if(!opts) opts = {};
        if(!opts.method) opts.method = 'GET';
        if(!opts.headers) opts.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        fetch(`/api/entry/user/${userEmail}/range/${startDate}/${endDate}`, opts)
            .then(response => {
                handleResponse(response, resolve, reject);
            })
            .catch(e => {
                reject(e);
            })
    })
}

function getEntriesInRange(startDate, endDate, opts) {
    return new Promise((resolve, reject) => {
        if(!opts) opts = {};
        if(!opts.method) opts.method = 'GET';
        if(!opts.headers) opts.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        fetch(`/api/entry/range/${startDate}/${endDate}`, opts)
            .then(response => {
                handleResponse(response, resolve, reject);
            })
            .catch(e => {
                reject(e);
            })
    })
}

function getEntriesByWorkOrderNumber(workOrderNumber, opts) {
    return new Promise((resolve, reject) => {
        if(!opts) opts = {};
        if(!opts.method) opts.method = 'GET';
        if(!opts.headers) opts.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        fetch(`/api/entry/workOrderNumber/${workOrderNumber}`, opts)
            .then(response => {
                handleResponse(response, resolve, reject);
            })
            .catch(e => {
                reject(e);
            })
    })
}

/**
 * Creates an `HoursReport` in the database and returns the created `HoursReport`.
 * @param {object} hoursReport An `HoursReport` object
 * @param {object} opts Options to pass to the `fetch` call (`headers`, `signal`, etc.) 
 * @returns {Promise} A promise that resolves with the created `HoursReport`
 */
function createHoursReport(hoursReport, opts) {
    return new Promise((resolve, reject) => {
        if(!opts) opts = {};
        if(!opts.method) opts.method = 'POST';
        if(!opts.headers) opts.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        opts.body = JSON.stringify(hoursReport);
        fetch('/api/hoursreports', opts)
            .then(response => {
                handleResponse(response, resolve, reject);
            })
            .catch(e => {
                reject(e);
            })
    })
}

/**
 * Calls /api/xlsx and returns the blob
 * @param {any} dataSets The dataset object in the format used by `excel4node` (or an array of them)
 * @param {object} config (Optional) config object in the format used by `excel4node`
 * @param {object} opts Options to pass to the `fetch` call (`headers`, `signal`, etc.) 
 * @returns {Promise} A promise that resolves with the created blob
 */
function createXLSX(dataSets, config, opts) {
    return new Promise((resolve, reject) => {
        if(!opts) opts = {};
        if(!opts.method) opts.method = 'POST';
        if(!opts.headers) opts.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        const dataSetsIsArray = JSON.stringify(dataSets).startsWith('[');
        let body = {dataSets: dataSetsIsArray ? dataSets : [dataSets]};
        if(config) body.config = config;
        if(config && config.skipHeaders) body.skipHeaders = true;
        opts.body = JSON.stringify(body);

        fetch('/api/xlsx', opts)
            .then(response => {
                handleResponse(response, resolve, reject, 'blob');
            })
            .catch(e => {
                reject(e);
            })
    })
}

function getHolidays(opts) {
    return new Promise((resolve, reject) => {
        if(!opts) opts = {};
        if(!opts.method) opts.method = 'GET';
        if(!opts.headers) opts.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        fetch('/api/holidays', opts)
            .then(response => {
                handleResponse(response, resolve, reject);
            })
            .catch(e => {
                reject(e);
            })
    })
}

function createHoursReportByRange(startDate, endDate, returnAs) {
    return new Promise((resolve, reject) => {
        const opts = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                startDate: startDate,
                endDate: endDate,
                returnAs: returnAs
            })
        }
        fetch('/api/hoursreports/by-range', opts)
            .then(response => {
                handleResponse(response, resolve, reject, returnAs === "report" ? 'json' : 'blob');
            })
            .catch(e => {
                reject(e);
            })
    })
}

function getHoursReportBlobById(id) {
    return new Promise((resolve, reject) => {
        const opts = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        fetch(`/api/hoursreports/download/${id}`, opts)
            .then(response => {
                handleResponse(response, resolve, reject, 'blob');
            })
            .catch(e => {
                reject(e);
            })
    })
}

function getWorkOrderEntries(opts) {
    return new Promise((resolve, reject) => {
        if(!opts) opts = {};
        if(!opts.method) opts.method = 'GET';
        if(!opts.headers) opts.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        fetch('/api/workorderentries', opts)
            .then(response => {
                handleResponse(response, resolve, reject);
            })
            .catch(e => {
                reject(e);
            })
    })
}

function getWorkOrderEntriesInRange(startDate, endDate, opts) {
    return new Promise((resolve, reject) => {
        if(!opts) opts = {};
        if(!opts.method) opts.method = 'GET';
        if(!opts.headers) opts.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        fetch(`/api/workorderentries/range/${startDate}/${endDate}`, opts)
            .then(response => {
                handleResponse(response, resolve, reject);
            })
            .catch(e => {
                reject(e);
            })
    })
}

function getQbItemsLike(term, opts) {
    return new Promise((resolve, reject) => {
        if(!opts) opts = {};
        if(!opts.method) opts.method = 'GET';
        if(!opts.headers) opts.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        fetch(`/qb/items/like/${term}`, opts)
            .then(response => {
                handleResponse(response, resolve, reject);
            })
            .catch(e => {
                reject(e);
            })
    })
}

function createWorkOrderEntry(workOrder) {
    return new Promise((resolve, reject) => {
        let opts = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(workOrder)
        };
        fetch(`/api/workorderentries`, opts)
            .then(response => {
                handleResponse(response, resolve, reject);
            })
            .catch(e => {
                reject(e);
            })
    })
}

function updateWorkOrderEntry(id, workOrder) {
    return new Promise((resolve, reject) => {
        let opts = {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(workOrder)
        };
        fetch(`/api/workorderentries/${id}`, opts)
            .then(response => {
                handleResponse(response, resolve, reject);
            })
            .catch(e => {
                reject(e);
            })
    })
}

function deleteWorkOrderEntry(id) {
    return new Promise((resolve, reject) => {
        let opts = {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        fetch(`/api/workorderentries/${id}`, opts)
            .then(response => {
                handleResponse(response, resolve, reject);
            })
            .catch(e => {
                reject(e);
            })
    })
}

function getEntriesInRangeByQBCustomer(startDate, endDate) {
    return new Promise((resolve, reject) => {
        getUsers()
        .then(async users => {
            // Organize users by their QB customer name
            let uniqueCustomers = {};
            for(const user of users) {
                if(user.qbCustomer.name !== null && user.qbCustomer.name.includes("HMIS")) {
                    if(user.qbCustomer.name.includes("74122") && startDate >= "2022-10-01") continue; // Contract 74122 expired on this date, no need to show the blobs in this case.
                    if(!uniqueCustomers[user.qbCustomer.name]) {
                        uniqueCustomers[user.qbCustomer.name] = [user];
                    }
                    else {
                        uniqueCustomers[user.qbCustomer.name].push(user);
                    }
                }
            }
            
            // Get entries for each QB customer
            let entriesByQbCustomerName = {};
            for(const qbCustomerName in uniqueCustomers) {
                let entriesForName = [];
                for(const user of uniqueCustomers[qbCustomerName]) {
                    try {
                        const entriesForUser = await getEntriesForUser(user.email, startDate, endDate);
                        for(const entry of entriesForUser) {
                            entriesForName.push(entry);
                        }
                    }
                    catch(err) {
                        reject(err);
                    }
                }
                entriesByQbCustomerName[qbCustomerName] = entriesForName;
            }

            resolve(entriesByQbCustomerName);
        })
    })
}

function getUserItemPairs(month, year) {
    return new Promise((resolve, reject) => {
        let opts = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        fetch(`/api/user/items/month/${month}/year/${year}`, opts)
            .then(response => {
                handleResponse(response, resolve, reject);
            })
            .catch(e => {
                reject(e);
            })
    })
}

function getUserItemPairsInRange(startDate, endDate) {
    return new Promise((resolve, reject) => {
        let opts = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        fetch(`/api/user/items/startDate/${startDate}/endDate/${endDate}`, opts)
            .then(response => {
                handleResponse(response, resolve, reject);
            })
            .catch(e => {
                reject(e);
            })
    })
}

function deleteEntryByID(id, opts) {
    return new Promise((resolve, reject) => {
        if(!opts) opts = {};
        if(!opts.method) opts.method = 'DELETE';
        if(!opts.headers) opts.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        fetch(`/api/entry/${id}`, opts)
        .then(response => {
            handleResponse(response, resolve, reject);
        })
        .catch(e => {
            reject(e);
        })
    })
}

function createEntry(entry, opts) {
    return new Promise((resolve, reject) => {
        if(!opts) opts = {};
        if(!opts.method) opts.method = 'POST';
        if(!opts.headers) opts.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        opts.body = JSON.stringify(entry);
        fetch('/api/entry', opts)
        .then(response => {
            handleResponse(response, resolve, reject);
        })
        .catch(e => {
            reject(e);
        })
    })
}

function updateEntry(id, entry, opts) {
    return new Promise((resolve, reject) => {
        if(!opts) opts = {};
        if(!opts.method) opts.method = 'PUT';
        if(!opts.headers) opts.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        opts.body = JSON.stringify(entry);
        fetch(`/api/entry/${id}`, opts)
        .then(response => {
            handleResponse(response, resolve, reject);
        })
        .catch(e => {
            reject(e);
        })
    })
}

function updateHolidays(holidays) {
    return new Promise((resolve, reject) => {
        let opts = {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({holidays: holidays})
        }
        fetch('/api/holidays', opts)
        .then(response => {
            handleResponse(response, resolve, reject);
        })
        .catch(e => {
            reject(e);
        })
    })
}

function getUsersHoursInRange(userEmail, startDate, endDate) {
    return new Promise((resolve, reject) => {
        let opts = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }
        fetch(`/api/user/${userEmail}/hours/${startDate}/${endDate}`, opts)
        .then(response => {
            handleResponse(response, resolve, reject);
        })
        .catch(e => {
            reject(e);
        })
    })
}

function getHoursReports() {
    return new Promise((resolve, reject) => {
        let opts = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }
        fetch(`/api/hoursreports`, opts)
        .then(response => {
            handleResponse(response, resolve, reject);
        })
        .catch(e => {
            reject(e);
        })
    })
}

function getQbTermByName(termName) {
    return new Promise((resolve, reject) => {
        let opts = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }
        fetch(`/qb/term/name/${termName}`, opts)
        .then(response => {
            handleResponse(response, resolve, reject);
        })
        .catch(e => {
            reject(e);
        })
    })
}

function createQbInvoice(customerId, lineItems, term) {
    return new Promise((resolve, reject) => {
        let opts = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                customerId: customerId,
                lineItems: lineItems,
                term: term
            })
        }
        fetch(`/qb/invoice`, opts)
        .then(response => {
            handleResponse(response, resolve, reject);
        })
        .catch(e => {
            reject(e);
        })
    })
}

function getQbCustomerByContractNumber(contractNumber) {
    return new Promise((resolve, reject) => {
        let opts = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }
        fetch(`/qb/customer/contractNumber/${contractNumber}`, opts)
        .then(response => {
            handleResponse(response, resolve, reject);
        })
        .catch(e => {
            reject(e);
        })
    })
}

function getHmisMonthlyDataSet(startDate, endDate, workOrderNumber) {
    return new Promise((resolve, reject) => {
        let opts = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }
        fetch(`/api/report/hmis/${startDate}/${endDate}/${workOrderNumber}`, opts)
        .then(response => {
            handleResponse(response, resolve, reject);
        })
        .catch(e => {
            reject(e);
        })
    })
}

function lockEntries(entryIds) {
    return new Promise((resolve, reject) => {
        let opts = {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                entryIds: entryIds
            })
        }
        fetch(`/api/lockentries`, opts)
        .then(response => {
            handleResponse(response, resolve, reject);
        })
        .catch(e => {
            reject(e);
        })
    })
}

function getHMISReportBundles() {
    return new Promise((resolve, reject) => {
        let opts = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }
        fetch(`/api/hmisreportbundle`, opts)
        .then(response => {
            handleResponse(response, resolve, reject);
        })
        .catch(e => {
            reject(e);
        })
    })
}

function createHMISReportBundle(bundle) {
    return new Promise((resolve, reject) => {
        let opts = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(bundle)
        }
        fetch(`/api/hmisreportbundle`, opts)
        .then(response => {
            handleResponse(response, resolve, reject);
        })
        .catch(e => {
            reject(e);
        })
    })
}

function getMileageEntriesForUserInMonth(userEmail, monthStr, yearStr, signal) {
    return new Promise((resolve, reject) => {
        let opts = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            signal
        }
        fetch(`/api/mileageentries/user/${userEmail}/month/${monthStr}/year/${yearStr}`, opts)
        .then(response => {
            handleResponse(response, resolve, reject);
        })
        .catch(e => {
            reject(e);
        })
    })
}

function getMileageEntriesInMonth(monthStr, yearStr, signal) {
    return new Promise((resolve, reject) => {
        let opts = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            signal
        }
        fetch(`/api/mileageentries/month/${monthStr}/year/${yearStr}`, opts)
        .then(response => {
            handleResponse(response, resolve, reject);
        })
        .catch(e => {
            reject(e);
        })
    })
}

function createMileageEntry(mileageEntry, signal) {
    return new Promise((resolve, reject) => {
        let opts = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(mileageEntry),
            signal
        }
        fetch('/api/mileageentries', opts)
        .then(response => {
            handleResponse(response, resolve, reject);
        })
        .catch(e => {
            reject(e);
        })
    })
}

function updateMileageEntry(id, mileageEntry, signal) {
    return new Promise((resolve, reject) => {
        let opts = {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(mileageEntry),
            signal
        }
        fetch(`/api/mileageentries/${id}`, opts)
        .then(response => {
            handleResponse(response, resolve, reject);
        })
        .catch(e => {
            reject(e);
        })
    })
}

function deleteMileageEntry(id, signal) {
    return new Promise((resolve, reject) => {
        let opts = {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            signal
        }
        fetch(`/api/mileageentries/${id}`, opts)
        .then(response => {
            handleResponse(response, resolve, reject);
        })
        .catch(e => {
            reject(e);
        })
    })
}

const api = {
    getUsers: getUsers,
    getEntriesForUser: getEntriesForUser,
    getEntriesByWorkOrderNumber: getEntriesByWorkOrderNumber,
    getHolidays: getHolidays,
    getHoursReportBlobById: getHoursReportBlobById,
    getWorkOrderEntries: getWorkOrderEntries,
    getWorkOrderEntriesInRange: getWorkOrderEntriesInRange,
    getQbItemsLike: getQbItemsLike,
    getEntriesInRangeByQBCustomer: getEntriesInRangeByQBCustomer,
    getUserItemPairs: getUserItemPairs,
    getUserItemPairsInRange: getUserItemPairsInRange,
    getUsersHoursInRange: getUsersHoursInRange,
    getQbTermByName: getQbTermByName,
    getQbCustomerByContractNumber: getQbCustomerByContractNumber,
    getHmisMonthlyDataSet: getHmisMonthlyDataSet,
    getHoursReports: getHoursReports,
    getHMISReportBundles,
    getMileageEntriesForUserInMonth,
    getMileageEntriesInMonth,
    createHoursReport: createHoursReport,
    createXLSX: createXLSX,
    createHoursReportByRange: createHoursReportByRange,
    createWorkOrderEntry: createWorkOrderEntry,
    createEntry: createEntry,
    createHMISReportBundle: createHMISReportBundle,
    createQbInvoice: createQbInvoice,
    createMileageEntry,
    updateWorkOrderEntry: updateWorkOrderEntry,
    updateEntry: updateEntry,
    updateHolidays: updateHolidays,
    updateMileageEntry,
    deleteWorkOrderEntry: deleteWorkOrderEntry,
    deleteEntryByID: deleteEntryByID,
    deleteMileageEntry,
    lockEntries: lockEntries,
    getEntriesInRange: getEntriesInRange
}

export default api;