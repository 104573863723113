import React, { useState, useEffect } from 'react';

import helpers from '../helpers';
import MonthSelector from './MonthSelector';
import LoadingSpinner from './LoadingSpinner';
import api from '../api';

const MileageReimbursement = ({}) => {
    const [monthBaseDayStr, setBaseDayStr] = useState(helpers.dateToISOFragment(new Date()));
    const [entries, setEntries] = useState(null);

    useEffect(() => {
        if(entries === null) {
            const tokens = monthBaseDayStr.split('-');
            if(tokens.length < 3) {
                console.error('monthBaseDayStr improperly formatted');
            }
            else {
                const monthStr = tokens[1];
                const yearStr = tokens[0];
                api.getMileageEntriesInMonth(monthStr, yearStr, undefined)
                .then(newEntries => setEntries(newEntries))
                .catch(e => {
                    if(e.name !== "AbortError") {
                        console.error(e);
                        alert("Failed to retrieve entries. Please refresh and try again. Alert the website administrator if the problem persists.");
                    }
                    else {
                        console.log('getMileageEntriesInMonth aborted');
                    }
                })
            }
        }
    }, [entries]);

    useEffect(() => {
        setEntries(null);
    }, [monthBaseDayStr]);

    const uniqueEmails = () => {
        let emails = [];
        for(const entry of entries) {
            if(!emails.includes(entry.user.email)) {
                emails.push(entry.user.email);
            }
        }
        return emails;
    }

    const totalMileage = (_entries) => {
        let total = 0;
        for(const entry of _entries) {
            total += entry.mileage;
        }
        return total;
    }

    return (
        <div>
            <center>
                <h2>Mileage Reimbursement</h2>
                <h3>
                    <MonthSelector setBaseDayStr={setBaseDayStr}/>
                </h3>
            </center>
            {entries === null ? 
                <LoadingSpinner size={75}/>
            : 
            <>
            {entries.length > 0 ? 
            <>
            {uniqueEmails().map((email, i) => <>
            <h4>Mileage for {email}</h4>
            <table key={i} className="table table-striped table-bordered table-layout-fixed w-100">
                <colgroup>
                    <col span="1" style={{width: '10%'}}/>
                    <col span="1" style={{width: '80%'}}/>
                    <col span="1" style={{width: '10%'}}/>
                </colgroup>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Sites Visited</th>
                        <th>Mileage</th>
                    </tr>
                </thead>
                <tbody>
                    <>
                    {entries.filter(entry => entry.user.email === email).map((entry, i) => <tr key={i}>
                        <td>{helpers.dateFromISOFragment(entry.day).toLocaleDateString()}</td>
                        <td className="text-start">{entry.description.split('\n').map((p, i) => <><span key={i}>{p}</span><br/></>)}</td>
                        <td className="text-end">{entry.mileage}</td>
                    </tr>)}
                    <tr>
                        <td colSpan={2} className="text-end"><strong>Total Mileage:</strong></td>
                        <td className="text-end">{totalMileage(entries.filter(entry => entry.user.email === email))}</td>
                    </tr>
                    <tr>
                        <td colSpan={2} className="text-end"><strong>Reimbursement for {email} (x$0.38):</strong></td>
                        <td className="text-end">${(totalMileage(entries.filter(entry => entry.user.email === email)) * 0.38).toFixed(2)}</td>
                    </tr>
                    </>
                </tbody>
            </table>
            </>)}
            </>
            :
            <p className="text-muted"><i>No entries for this month yet.</i></p>
            }
            </>
            }
        </div>
    )
}

export default MileageReimbursement;